
import { Component, Vue } from "vue-property-decorator";
import ExaGroupsView from "./ExaGroupsView.vue";
import ExaSkuListView from "./ExaSkuListView.vue";
import ExaQuestionList from "./ExaQuestionList.vue";
@Component({
  components: {
    ExaGroupsView,
    ExaSkuListView,
    ExaQuestionList
  }
})
export default class MarketView extends Vue {
  tab: string = "tab-items";

  declare user: { is_admin: boolean };
}
