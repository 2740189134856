
import { get, post } from "@/api/axios";
import { IExaSku } from "@/state/models";
import { Component, Vue } from "vue-property-decorator";
import TableRootEntities from "@/components/TableRootEntities.vue";

export declare type IEx2aSku = {
    name: string;
    tasks: string[];
    tags: string[];
    theme: string;
    _id: string;
}

@Component({
    components: {
        TableRootEntities
    }
})
export default class ExaSkuListView extends Vue {
    entities: IExaSku[] = [];

    fields: Array<any> = [
        { icon: "mdi-clock", field: "name" },
    ];

    headers: Array<{ text: string, align?: "start", filterable?: boolean, value: string }> = [
        {
            text: "Имя",
            align: "start",
            value: "name"
        },
        {
            text: "Тема",
            value: "theme"
        }
    ];

    mounted() {
        get(`/api/@examination/exa/sku`)
            .then(x => {
                this.entities = x.data;
            });
    }

    caption(sku: IExaSku) {
        return sku?.name ?? "<unknown>";
    }

    addNewSKU(o: { title: string }) {
        console.log("addNewSKU", o);
        post(`/api/@examination/exa/sku`, { name: o.title, theme: "<empty>", tags: [] })
            .then(x => {
                console.log("Success add empty sku", x.data)
                this.entities.push(x.data);
            });
    }
}
